.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    font-family: 'neue-haas-grotesk-display';
    font-size: 3rem;
}

.h1 {
    font-family: 'neue-haas-grotesk-display';
    font-size: 2.7rem;
}

.h2 {
    font-family: 'neue-haas-grotesk-display';
    font-size: 2.5rem;
}

.h3 {
    font-family: 'neue-haas-grotesk-display';
    font-size: 2.3rem;
}

.h4 {
    font-family: 'neue-haas-grotesk-display';
    font-size: 2rem;
}

.h5 {
    font-family: 'neue-haas-grotesk-display';
    font-size: 1.7rem;
}

.h6 {
    font-family: 'neue-haas-grotesk-display';
    font-size: 1.4rem;
}

@media screen and (min-width: 768px) {

    .title {
        font-size: 4rem;
    }

    .h1 {
        font-size: 3.7rem;
    }
    
    .h2 {
        font-size: 3.5rem;
    }
    
    .h3 {
        font-size: 3.3rem;
    }
    
    .h4 {
        font-size: 3rem;
    }
    
    .h5 {
        font-size: 2.7rem;
    }
    
    .h6 {
        font-size: 2.4rem;
    }
    
}

@media screen and (min-width: 1024px) {

    .title {
        font-size: 5rem;
    }

    .h1 {
        font-size: 4rem;
    }
    
    .h2 {
        font-size: 3.7rem;
    }
    
    .h3 {
        font-size: 3.3rem;
    }
    
    .h4 {
        font-size: 3rem;
    }
    
    .h5 {
        font-size: 2.7rem;
    }
    
    .h6 {
        font-size: 2.4rem;
    }
    
}