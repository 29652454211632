.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.linkHeader {
    font-size: 2rem;
    text-decoration: none;
}

.linkHeader:hover {
    color: var(--accent1)
}