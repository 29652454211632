.container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
}

.container > a {
    margin: 5px;
}

.container > a:hover {
    color: var(--accent1);
}