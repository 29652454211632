.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button {
    background-color: var(--dark1);
    color: var(--accent1);
    border: 1px solid var(--accent1);
    border-radius: 50px;
    height: 40px;
    width: 100px;
    margin-top: 40px;
}

.button:hover{
    background-color: var(--accent1);
    color: var(--dark3);
    cursor: pointer;
}