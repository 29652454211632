.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    width: 300px;
}

.image:hover {
    border: 1px solid var(--accent1);
}

@media screen and (min-width: 768px){ 
    .image {
        width: 600px;
    }
    .image:hover {
        border: 1px solid var(--accent1);   
    }
}

@media screen and (min-width: 1024px){ 
    .image {
        width: 800px;
    }
    .image:hover {
        border: 1px solid var(--accent1);   
    }
}