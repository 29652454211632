.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.link {
    font-size: 2rem;
    text-decoration: none;
}

.link:hover {
    color: var(--accent1)
}