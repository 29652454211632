html {
    background-color: var(--dark1);
    color: var(--light1);
    box-sizing: border-box;
    margin: 0px;
  }
  
  *,
  *::before,
  *::after {
      box-sizing: inherit;
  }
      
  :root {
      font-size: 62.5%;
      --dark1: #0E1720;
      --dark2: #393E46;
      --dark3: #1A1A2E;
      --accent1: #3BE2AC;
      --accent2: #0F3460;
      --accent3: #E94560;
      --light1: #EEEEEE;
  }
  
  body {
    margin: 0;
    font-family: 'neue-haas-grotesk-text', 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  a {
    color: var(--light1)
  }
