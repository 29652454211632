.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    border-radius: 5px;
}


.paragraph1 {
    font-size: 1.7rem;
    font-weight: 700;
    padding-right: 10px;
    padding-left: 10px;
    margin-top: 40px;
    margin-bottom: 6px;
}

.paragraph2 {
    font-size: 1.5rem;
    font-weight: 400;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 6px;
}

.paragraphLast {
    font-size: 1.5rem;
    font-weight: 400;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 40px;
}

.portfolioParagraph {
    font-size: 1.5rem;
    font-weight: 400;
    padding-right: 10px;
    padding-left: 10px;
    margin-top: -10px;
    margin-bottom: 34px;
}

.span2 {
    color: var(--accent1);
    font-weight: 600;
}

.span3 {
    font-weight: 600;
}

@media screen and (min-width: 768px){ 
    .container {
        width: 600px;   
    }
    .paragraph1 {
        font-size: 2rem;
        padding-right: 30px;
        padding-left: 30px;
    }
    .paragraph2 {
        font-size: 1.8rem;
        padding-right: 30px;
        padding-left: 30px;
    }
    .paragraphLast {
        font-size: 1.8rem;
        padding-right: 30px;
        padding-left: 30px;
    }
    .portfolioParagraph {
        font-size: 1.8rem;
        padding-right: 30px;
        padding-left: 30px;
        margin-top: -20px;
        margin-bottom: 44px;
    }
}

@media screen and (min-width: 1024px){ 
    .container {
        width: 800px;
    }
    .paragraph1 {
        font-size: 2.2rem;
        padding-right: 40px;
        padding-left: 40px;
    }
    .paragraph2 {
        font-size: 1.9rem;
        padding-right: 40px;
        padding-left: 40px;
    }
    .paragraphLast {
        font-size: 1.9rem;
        padding-right: 40px;
        padding-left: 40px;
    }
    .portfolioParagraph {
        font-size: 1.9rem;
        padding-right: 40px;
        padding-left: 40px;
        margin-top: -20px;
        margin-bottom: 44px;
    }
}